/* YourStyles.module.css */
.form_section h1{
    color: #FFF;
    text-align: center;
}
.form {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .form label{
    color: #FFF;
  }
  .formColumn {
    flex: 1;
    padding: 0 15px; /* Adjust padding as needed */
  }
  
  .formGroup {
    margin-bottom: 20px; /* Adjust margin as needed */
  }
  
  label {
    display: block;
    margin-bottom: 8px; /* Adjust margin as needed */
  }
  
  input,
  select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: none;
    outline: none;
    margin-bottom: 10px; /* Adjust margin as needed */
    box-sizing: border-box;
  }
  
  /* Add additional styling as needed */
  
  .countryCode{
    width: 110px;
  }
  .formgroupphone{
    display: flex;
    gap: 10px;
    align-items: center;
  }


  .credits_footer{
    height: 50px;
    border-top: 2px solid #403f3f;
    display: flex;
    color: #FFF;
    justify-content: center;
    align-items: center;
}
.credits{
    width: 90%;
    display: flex;
    justify-content:space-between;
}
.credits a{
    width: 100%;
    display: flex;
    justify-content: center;
    color: #FFF;
    font-size: 16px;
    text-decoration: none;
}

a{
    text-decoration: none;
}

 button{
    padding: 10px 20px;
    color: #000;
    font-size: 15px;
    background: #fff;
    border: none;
    border-radius: 8px;
    font-weight: bold;
}

.formGroup_right {
  margin-bottom: 20px; /* Adjust margin as needed */

}
.formGroup_left {
  display: none;
  margin-bottom: 20px; /* Adjust margin as needed */
}



@media (max-width:650px){
  .form{
    flex-wrap: wrap;
  }
  .credits_footer{ 
    position: relative;
  }
  .formGroup_right {
    margin-bottom: 20px; /* Adjust margin as needed */
   display: none;  
  }
  .formGroup_left {
    display: block;
    margin-bottom: 20px; /* Adjust margin as needed */
  }
  
}

@media (max-width:650px){
  .credits_footer{
      display:grid !important;
  }
  .credits{
      display:grid !important;
      gap:15px;
  }
}

.hubspotcontainer{
  width: 100%;
  height: 100vh;
  display:flex;
  align-items: center;
}

.hubspotform{
  width: 95%;
  margin: auto;
}
.thankyou{
  width: 100%;
}
.thankyou h1{
  color: #FFF;
  font-size: 80px;
  font-weight: bold;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
  margin: auto;
  position: absolute;
  z-index: 88;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  font-style: italic;
  text-shadow:#000;
}