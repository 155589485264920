.footer_section{
    width: 100%;
    background: #151515;
    border: 1px solid #151515;
}

.footer_subsection{
    margin: 96px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
}

.footer_subsection h2{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.63px;
    position: relative;
    z-index: 99;
}

.footer_subsection button{
    padding: 18px 24px;
    display: flex;
    height: 47px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 16px;
    color: #1D1D1D;
    leading-trim: both;
    text-edge: cap;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
    background: #FFF;
    border: none;
}

.credits_footer{
    position: relative;
    z-index: 99;
    height: 50px;
    border-top: 2px solid #403f3f;
    display: flex;
    color: #FFF;
    justify-content: center;
    align-items: center;
}
.credits{
    width: 90%;
    display: flex;
    justify-content:space-between;
}
.credits a{
    width: 100%;
    display: flex;
    justify-content: center;
    color: #FFF;
    font-size: 16px;
    text-decoration: none;
}

@media (max-width:650px){
    .credits_footer{
        display:grid !important;
    }
    .credits{
        display:grid !important;
        gap:15px;
    }
}

@media (max-width:650px){
    .footer_animation{
        display: none !important;
    }    
}

.footer_animation{
    width: 360px;
    height: 100%;
    float: right;
    overflow: hidden;
    position: absolute;
    margin-top: -200px;
    right: 0;
    background: transparent;
}