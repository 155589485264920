.brands_section{
    width: 100%;
    height: auto;
    background: #151515;
    border: 1px solid #151515;
}

.brands_subsection p{
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.63px;
}

.brands_subsection .brands{
  width: 100%;
  height: 150px;
  display: flex;
  column-gap: 20px;
  position: relative;
  margin-top: 20px;
}
.brands::-webkit-scrollbar{
    display: none;
}
.brand{
    width: 300px;
    height: 150px;
    border-radius: 28px;
    background: #ffffff;
    flex-shrink: 0 ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    overflow: hidden
    ;
  }
    

  @media (max-width:650px){
    .brands_subsection{
      width: 100%;
      margin: 0px;
    }
    .brands_subsection .brands{
      width: 100%;
    }
    .AnimationDot{
      display: none !important;
    }
  }



  .AnimationDot{
    left: 0;
    margin-left: -850px;
    margin-top: -450px;
    position: absolute;
    z-index: 0;
}

