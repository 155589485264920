.feed_section{
    width: 100%;
    background: #151515;
    border: 1px solid #151515;
}

.feed_subsection{
    margin: 96px;
    display: flex;
    flex-direction: column;
    gap: 38px;
}

.feed_subsection h2 {
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.63px;
}
.feed_subsection p{
    color: #c2c2c2;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.63px;
}
.category_section{
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 20px;
}
.category{
    display: flex;
    padding-left:24px;
    padding-right: 24px;
    height: 54px;
    border-radius: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #151515;
    color: #FFF;
    gap: 10px;
    box-shadow: 0px 4px 8px 0px #8E3FF1;
}

.feed_videos{
    display: flex;
    height: 590px;
    align-items: center;
    gap: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
}
.feed_videos::-webkit-scrollbar{
    display: none;
}
.video_feed{
    width: 344px;
    height: 590px;
    border-radius: 32px;
    flex-shrink: 0;
    background: #FFF;
    position:relative;
    display: flex;
    justify-content: center;
}
.video_feed video{
    width: 100%;
    height: 100%;
    border-radius: 32px;
    object-fit: cover;
}

.feed_carousel{
    display: flex;
    align-items: center;
}
.feed_carousel button{
    width: 50px;
    height: 50px;
    border-radius: 9px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
}

.feed_carousel svg{
    width: 50px;
}
.icon_section{
    width: 90%;
    margin-top: 10px;
    position:absolute;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.icon_section p{
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
}
.icons{
    display: flex;
    gap: 10px;
}
.sound_icon{
    position: relative;
    float: right;
    z-index: 99;
}
.cross_icon{
    float: right;
    
}

.join_section{
    width: 100%;
    bottom: 0;
    position:absolute;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.join_section button{
    display: flex;
    width: 312px;
    height: 60px;
    padding: 18px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    border: none;
    color: #151515;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.63px;
}


@media (max-width:650px){
    .feed_subsection{
      width: 100%;
      margin: 0px;
      margin-top: 90px;
      overflow: hidden;
    }
    .feed_subsection .video_feed{
      width: 300px;
    }
    .feed_videos{
        width: 300px;
        margin: auto;
    }
    .join_section button{
        width: 212px;
    }
    .feed_subsection h2{
       font-size: 38px;
    }
    .feed_subsection p{
        font-size: 15px;
    }
    .category_section{
        flex-wrap: wrap;
        gap: 20px;
    }

    @keyframes move{
        from{
            transform:translateX(0)
        }
        to{
            transform:translateX(-125%)
        }
    }

  }