.heading{
    color: #FFF;
    text-align:center;
}
.terms{
    padding:0px 50px;
}
.terms p , li {
  color:#FFF;
}
.cancellation{
    padding:10px 50px;   
}
.cancellation h2 , h3 ,h4 , p , li {
    color:#FFF;
}