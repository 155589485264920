.Investor_section{
    width: 100%;
    background: #151515;
    border: 1px solid #151515;
}

.Investor_subsection{
    margin: 96px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.Investor_subsection h2 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.63px;
}

.Investors{
    display: flex;
    height: 590px;
    align-items: center;
    gap: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.Investors::-webkit-scrollbar{
    display: none;
}
.Investor{
    width: 250px;
    height: 490px;
    border-radius: 32px;
    flex-shrink: 0;
    background: #FFF;
}
.Investor_subsection button{
    width: 136px;
    display: flex;
    height: 47px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 16px;
    color: #1D1D1D;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.63px;
    background: #FFF;
    margin: auto;
}


/* Investor card */

.card{
    width: 250px;
    height: 490px;
    border-radius: 28px;
    background: radial-gradient(50% 27.1566% at 50% 27.4%, rgb(98, 0, 132) 0%, #1d1d1d 100%);
}
.card_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card .image{
    margin-top: 25px;
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 95px;
    
}

.image img{
    width: 150px;
    margin-top: 40px;
    height: 150px;
    object-fit: cover;
}

.card_section h3{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
}
.card_section p{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
}
.details{
    display:grid;
    justify-content: center;
    align-items: center;
}
.company{
    height: 40px;
    border-radius: 10px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
    flex-shrink: 0;
    padding-left: 10px;
    padding-right: 10px;
}
.company img{
    width: 70px;
}
.card p{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
}

.card_footer{
    width: 90%;
    display: flex;
    justify-content: flex-end;
    gap:10px;
}

.company_section{
    width: 100px;
    height: 40px;
}
.link{
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.excompany{
    height: 40px;
    border-radius: 10px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
    flex-shrink: 0;
    padding-left: 10px;
    padding-right: 10px;
}
.excompany img{
    width: 70px;
}
.linkedin{
    width: 40px ;
    height: 40px;
    border-radius: 10px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.linkedin svg{
    width: 30px;
}
.emptyblock{
   height: 50px;
}



/* investor page */


.investors_section h1{
    color: #FFF;
    text-align: center;
}

.investor_page{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    gap: 30px;
    margin-top: 30px;
}


.credits_footer{
    height: 50px;
    border-top: 2px solid #403f3f;
    display: flex;
    color: #FFF;
    justify-content: center;
    align-items: center;
}
.credits{
    width: 90%;
    display: flex;
    justify-content:space-between;
}
.credits a{
    width: 100%;
    display: flex;
    justify-content: center;
    color: #FFF;
    font-size: 16px;
    text-decoration: none;
}

a{
    text-decoration: none;
}


@media (max-width:650px){
    .Investor_subsection{
        margin: 0px;
        margin-top: 90px;
    }
    .Investor_subsection h2{
        font-size: 30px;
    }
    .Investors{
        width: 250px;
        margin: auto;
    }
}


@media (max-width:650px){
    .credits_footer{
        display:grid !important;
    }
    .credits{
        display:grid !important;
        gap:15px;
    }
}