
.header_section{
    width: 100%;
    height: 95px;
    background: #151515;
    display: flex;
    justify-content: center;
}
.header_subsection{
    width: 95%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo_section{
  width: 200px;
}
.waitlist_button_section{
   width: 200px;
   height: 47px;
   position: relative;
   z-index: 99;
}
.waitlist_button_section button{
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: #FFF;
    color: #1D1D1D;
    leading-trim: both;
    text-edge: cap;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
    border: none;
}

@media (max-width:600px){
  .waitlist_button_section{
    display: none;
  }
  .logo_section{
    margin: auto;
  }
}