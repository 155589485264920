body {
    background-color: #151515;
  }
  
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box::before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    background-image: conic-gradient(
      from 180deg at 50% 50%,
      #fff 0deg,
      #b128e3c8 16.88deg,
      #b128e000 360deg
    );
    animation: animate 8s linear infinite;
  }
  
  .box::after {
    content: "";
    position: absolute;
    inset: 6px;
    background: #151515;
    border-radius: 50%;
  }
  
  .box2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box2::before {
    width: 130%;
    height: 130%;
    animation: animate 7.5s linear infinite;
  }
  
  .box2::after {
    inset: 6px;
  }
  
  .box3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box3::before {
    width: 130%;
    height: 130%;
    animation: animate 7s linear infinite;
  }
  
  .box3::after {
    inset: 6px;
  }
  
  .box4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box4::before {
    width: 130%;
    height: 130%;
    animation: animate 6.5s linear infinite;
  }
  
  .box4::after {
    inset: 6px;
  }
  
  .box5 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box5::before {
    width: 130%;
    height: 130%;
    animation: animate 6s linear infinite;
  }
  
  .box5::after {
    inset: 6px;
  }
  
  .box6 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box6::before {
    width: 130%;
    height: 130%;
    animation: animate 5.5s linear infinite;
  }
  
  .box6::after {
    inset: 6px;
  }
  
  .box7 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box7::before {
    width: 130%;
    height: 130%;
    animation: animate 5s linear infinite;
  }
  
  .box7::after {
    inset: 6px;
  }
  
  .box8 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box8::before {
    width: 130%;
    height: 130%;
    animation: animate 4.5s linear infinite;
  }
  
  .box8::after {
    inset: 6px;
  }
  
  .box9 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box9::before {
    width: 130%;
    height: 130%;
    animation: animate 3.5s linear infinite;
  }
  
  .box9::after {
    inset: 6px;
  }
  
  .box10 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .box10::before {
    width: 130%;
    height: 130%;
    animation: animate 3s linear infinite;
  }
  
  .box10::after {
    inset: 6px;
  }
  @keyframes animate {
      0% {
          transform: rotate(359deg);
      }
      100% {
          transform: rotate(0deg);
      }
  }
  