
.about_section{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
}
.about_subsection{
    height: auto;
    margin: 96px;
    display: flex;
    flex-direction: column;
    row-gap:38px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 999;
}

.heading_text h1{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    
}

.para_text p{
    color: #c2c2c2;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.63px;
}
.about_cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-evenly;
}
.about_card{
   width: 381px;
   height: 360px;
   border-radius: 28px;
   background: #111111;
   box-shadow: 0px 4px 8px 0px #8E3FF1;
}
.card_section{
    margin: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card_section p{
    text-align: center;
    color: #c2c2c2;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.63px;
}
.card_section h4{
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.63px;
}
.rectangle_image_section{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background:transparent;
}

@media (max-width:650px){
    .about_cards{
        
        height: max-content;
    }
    .about_card{
        width: 281px;
        height: 260px;
    }
    .rectangle_image_section{
        width: 50px;
        height: 50px;
    }
    .rectangle_image_section svg{
        width: 50px;
        height: 50px;
    }
    .card_section{
        
    } 
    .card_section h4{
        font-size: 15px;
    }
    .card_section p{
        font-size: 10px !important;
    }

}