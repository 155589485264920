.home_section{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #151515;
}
.home_subsection{
    width: 93%;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.text_section{
   width: 650px;
   height: 100%;
   display: flex;
   align-items: center;
}
.video_section{
  width: 334px;
  height: 100%;
  z-index: 99;
}
.texts{
    width: 100%;
    height: 381px;
    display: grid;
}
.texts span{
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.63px;
    position: relative;
    z-index: 99;
}
.texts p{
    color: #c2c2c2;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.63px;
    position: relative;
    z-index:99;
}
.texts button{
    width: 154px;
    height: 47px;
    border-radius: 16px;
    background: #FFF;
    color: #1D1D1D;
    leading-trim: both;
    text-edge: cap;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.63px;
    border: none;
}
.video_section .video{
    width: 100%;
    height: 100%;
    background: #1D1D1D;
    border-radius: 32px;
}
.video video{
   width: 100%; 
   height: 100%; 
   border-radius: 40px; 
   display: block; 
   object-fit: cover; 
}

.spinner{
    width: 40%;
    position: absolute;
    right: 0;
    left: 55%;
    top: 50px;
    animation: spinner 5s infinite;
}

@keyframes spinner{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-360deg);
    }
}


@media (max-width:650px) {
    .home_subsection{
        display: grid;
        gap: 40px;
        height: max-content;
        align-items: center;
    }
    .text_section{
        width: 100%;
        height: max-content;
    }
    .texts{
        height: max-content;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .texts span, p{
        text-align: center;
    }
    .video_section{
        width:234px !important;
        margin: auto;
    }
    .Animation_section{
        display:none !important;
    }
}


.Animation_section{
    width: 650px;
    height: 800px;
    float: right;
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index:0;
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
}